@import "./site.variables";

/* Semantic UI variables */

@mobileBreakpoint: 320px;
@tabletBreakpoint: 768px;
@computerBreakpoint: 992px;
@smallMonitorBreakpoint: 1200px;
@largeMonitorBreakpoint: 1300px;
@widescreenMonitorBreakpoint: 1900px;

/* Fonts */

@font-face {
  font-family: source-sans-pro;
  src: url("~@less/fonts/SourceSans3-VariableFont_wght.ttf");
  font-style: normal;
}

@font-face {
  font-family: source-sans-pro;
  src: url("~@less/fonts/SourceSans3-Italic-VariableFont_wght.ttf");
  font-style: italic;
}

/*******************************
  CSS Reset
*******************************/

/* Modified Meyer Reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
// em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
// strong,
// sub,
// sup,
// tt,
var,
// b,
u,
// i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  line-height: 1;
  letter-spacing: -0.25px;
  overscroll-behavior: contain;
}

/*******************************
  Global Styles
*******************************/

html {
  font-size: 12.5px;
}

body {
  background-color: @color-background;
  color: @color-text-primary;
  font-family: source-sans-pro, "Source Sans Pro (OTF)", sans-serif;
}

:-webkit-full-screen {
  background: @color-background;
  color: @color-text-primary;
  font-family: source-sans-pro, "Source Sans Pro (OTF)", sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  color: @color-text-primary;
  text-decoration: none;

  &:hover {
    color: @color-primary-red;
    text-decoration: underline;
  }
}

/*******************************
  Global Classes
*******************************/

.main-background-image {
  background-image: url("~@less/images/skladane-knihy-pozadi.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

p.centered {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.white-bg {
  background: @color-text-white;
}

.text-big {
  font-weight: 600;
}

.text-small {
  font-weight: 300;
}

.top-tab {
  margin-top: 0.5rem;
}

.bottom-tab {
  margin-bottom: 0.5rem;
}

.left-tab {
  margin-left: 1rem;
}

.right-tab {
  margin-right: 1rem;
}

.text-in-paragraph {
  font-size: 1.375rem;
  line-height: 1.875rem;
}

.record-title {
  .text-big();
  font-size: 1.75rem;
  line-height: 2.375rem;
  color: @color-primary-red;
  letter-spacing: 0.03em;
}

.record-title-en {
  line-height: 2rem;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.record-description {
  font-size: 1.375rem;
  line-height: 2rem;
}

.red-bg-container {
  background-color: @color-primary-red;
  color: @color-text-white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.abstract-text {
  .text-in-paragraph();
  text-align-last: left;
  text-align: justify;
}

.record-container {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
  color: @color-text-primary;
  background: @color-text-white;
  font-family: source-sans-pro, "Source Sans Pro (OTF)", sans-serif;
  .text-small();
}

.sidebar-link-item-container {
  display: block;
  padding-top: 1.66rem;
  padding-left: 2rem;
  padding-right: 1.62rem;
  padding-bottom: 1.66rem;
  font-family: source-sans-pro, "Source Sans Pro (OTF)", sans-serif;
}

/*******************************
  Header
*******************************/

.language-selector {
  padding-top: 15px;
  padding-bottom: 15px;
  color: @color-text-white;
  text-align: right;
  font-family: source-sans-pro, "Source Sans Pro (OTF)", sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
}

.header-container {
  background: @color-primary-red;
  margin-bottom: 21.99px;

  .language-selector();

  a {
    color: @color-text-white;
    text-decoration: none;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

/*******************************
  Body - Search
*******************************/

// Navigation Breadcrumbs

.navigation-container {
  margin-bottom: clamp(15px, calc(15px + 1.5vw), 27px);

  .navigation-breadcrumbs {
    font-family: source-sans-pro, "Source Sans Pro (OTF)", sans-serif;
    font-size: 1.2rem;
    line-height: 21.6px;
    color: @color-text-primary;

    a.section {
      color: @color-text-primary;
      text-decoration: none;

      &:not(:last-child):hover {
        text-decoration: underline;
      }

      &:last-child {
        color: @color-primary-red;
        display: inline-block;
        cursor: none;
        pointer-events: none;
      }
    }
  }
}

// Main Content Heading

.main-heading-container {
  .heading-text {
    .text-big();
    font-family: source-sans-pro, "Source Sans Pro (OTF)", sans-serif;
    font-size: 4.75rem;
    line-height: 5.25rem;
    color: @color-text-primary;

    @media (max-width: @largeMonitorBreakpoint) {
      font-size: 3.8rem;
      line-height: 2.75rem;

      @media (max-width: @tabletBreakpoint) {
        font-size: 3rem;
      }
    }
  }
}

#uct-header-title-text {
  .left-tab();
  line-height: 400%;
  position: relative;
  margin-top: -10px;

  @media (max-width: @largeMonitorBreakpoint) {
    margin-top: -6px;
  }
}

#uct-header-logo {
  @media (max-width: @largeMonitorBreakpoint) {
    width: 66px;
    height: auto;
    font-size: 0.85714286rem;
  }
}

// Main Content Search

.main-search-container {
  border: 0.08rem solid @color-secondary-grey;
}

.search-all-link {
  font-family: source-sans-pro, "Source Sans Pro (OTF)", sans-serif;
  font-size: 1.375rem;
  line-height: 1.25rem;
  color: @color-text-primary;
  text-align: right;
  text-decoration: underline;

  @media (max-width: @largeMonitorBreakpoint) {
    font-size: 1.2rem;
    line-height: 0.625rem;
  }
}

// Main Content Container (Listing)

.listing-container {
  .record-container();

  .listing-heading {
    .abstract-text();
    font-size: 1.375rem;
    line-height: 1.875rem;

    @media (max-width: @largeMonitorBreakpoint) {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }

  .listing-search-results {
    font-size: 1.125rem;
    line-height: 0.625rem;
    letter-spacing: 0.03em;
  }

  .listing-item {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    overflow-wrap: break-word;

    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }

    a {
      text-decoration: none;
      color: @color-text-primary;

      .listing-item-title {
        .record-title();

        @media (max-width: @largeMonitorBreakpoint) {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }

      .listing-item-title-en {
        .record-title-en();

        @media (max-width: @largeMonitorBreakpoint) {
          font-size: 1.25rem;
          line-height: 1.75rem;
        }
      }
    }

    .listing-item-description {
      .record-description();

      @media (max-width: @largeMonitorBreakpoint) {
        font-size: 1.2rem;
        line-height: 1.5rem;
      }
    }

    .listing-item-creator {
      .listing-item-description();
      .text-big();
      .top-tab();
    }

    .listing-item-defended {
      .listing-item-description();
      .bottom-tab();
    }

    .listing-item-abstract {
      .listing-item-description();

      &:not(:last-child) {
        margin-bottom: 0.3rem;
      }
    }
  }
}

// Facets

.facets-container {
  margin-top: -1rem;
  margin-bottom: -1rem;

  .facets-item {
    .sidebar-link-item-container();
    background-color: @color-secondary-grey;
    color: @color-text-primary;

    &:not(:last-child) {
      margin-bottom: 1.69rem;
    }

    a {
      color: @color-text-primary;
      text-decoration: none;
      
      p {
        .text-big();
        display: flex;
        justify-content: space-between;
        font-size: 1.375rem;
        letter-spacing: 0.02rem;
        color: @color-text-primary;
        word-wrap: anywhere;

        span:hover {
          text-decoration: underline;
        }

        .facet-dropdown-caret {
          font-size: 1.2rem;
          transform: scale(1.5, 1);
          color: @color-primary-red;
          display: inline-block;
          text-decoration: none;
          align-self: self-start;
        }

        @media (max-width: @largeMonitorBreakpoint) and (min-width: @tabletBreakpoint) {
          font-size: 1.15rem;

          .facet-dropdown-caret {
            font-size: 1rem;
          }
        }
      }
    }

    @media (max-width: @largeMonitorBreakpoint) and (min-width: @tabletBreakpoint) {
      padding: 1.1rem;

      .facet-checkbox,
      .facet-count {
        font-size: 1rem;
      }
    }
  }
}

.facet-accordion-content {
  .top-tab();
}

.facet-value-element {
  display: flex;
  justify-content: space-between;
  gap: 8.75px;
}

.facet-checkbox {
  word-wrap: break-word;
}

.facet-count {
  .text-small();
  font-size: 1.2rem;
}

// Mobile Sidebar Facets

#sidebar-menu .accordion-title {
  font-size: 1.375rem;
}

/*******************************
  Body - Detail
*******************************/

// Main Content

.detail-record-description {
  .record-description();
  .red-bg-container();
  text-decoration: none;
  display: inline-block;

  &:hover {
    color: @color-text-white;
    text-decoration: underline;
  }
}

.detail-author-name {
  .record-title();
  color: @color-text-primary;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-decoration: none;

  &:hover {
    color: @color-primary-red;
    text-decoration: underline;
  }
}

.button-as-link {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  color: @color-primary-red;
  cursor: pointer;
  text-decoration: underline;
}

.custom-caret {
  font-size: 0.7em !important;

  &.caret.down {
    padding-left: 0.1em;
    transform: scale(1.5, 1) translateY(-0.17em);

    &::before {
      content: "▼" !important;
    }
  }

  &.caret.up {
    padding-left: 0.2em;
    transform: scale(1.5, 1) translateY(-0.17em);

    &.caret.up::before {
      content: "▲" !important;
    }
  }
}

.detail-record-container {
  .record-container();
  background-color: transparent;

  @media (max-width: @tabletBreakpoint) {
    padding: inherit;
  }
}

.detail-metadata-row {
  line-height: 1.5rem;

  &:not(:last-child) {
    padding-bottom: 0 !important;
  }

  @media (max-width: @tabletBreakpoint) {
    line-height: 1.875rem;

    dt {
      padding-left: 0.5rem !important;
    }

    dd {
      padding-left: 2rem !important;
      padding-right: 0.5rem !important;
    }
  }
}

.detail-metadata-row-value {
  display: inline-block;

  &:not(:last-child) {
    padding-bottom: 0.5rem;
  }
}

.detail-metadata-defended-red-label {
  .red-bg-container();
  margin-left: 3rem;
}

// Sidebar

.link-with-paragraph {
  width: 100%;
  text-decoration: none;
  background-color: transparent;
  color: @color-text-primary;
  border: 2px solid @color-secondary-grey;

  &:hover {
    cursor: pointer;
    color: @color-text-primary;
    text-decoration: underline;
  }

  p {
    .text-big();
    font-size: 1.375rem;
    letter-spacing: 0.02rem;
    line-height: 1.375rem;
    text-align: center;
    word-wrap: break-word;
  }
}

.detail-sidebar-link-container {
  .sidebar-link-item-container();

  &:first-child {
    .link-with-paragraph();

    background-color: @color-primary-red;
    color: @color-text-white;
    border: none;

    &:hover {
      color: @color-text-white;
    }
  }

  &:not(:last-child) {
    margin-bottom: 1.69rem;
  }

  .link-with-paragraph();
}

/*******************************
  Footer
*******************************/

#footer-divider {
  height: 1em;
  background: @color-primary-red;
}

.footer-text {
  .text-small();
  font-family: source-sans-pro, "Source Sans Pro (OTF)", sans-serif;
  line-height: 1.5rem;
  color: @color-text-primary;

  a {
    color: @color-text-primary;

    &:hover {
      text-decoration: underline;
    }
  }
}

.text-right-align {
  text-align: right;
}
